<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vg_button_group">
        <el-button type="primary" @click="submit('userForm')" size="small">保存</el-button>
        <el-button @click="cancel" size="small">取消</el-button>
      </div>
      <el-form ref="userForm" :model="userForm" :rules="rules" size="mini" class="vg_partition">
        <!--label-width="120px"-->
        <el-row type="flex" justify="space-around">
          <el-col :md="4">
            <el-form-item label="账号" prop="user_name">
              <el-input
                v-model="userForm.user_name"
                @input="userForm.user_name = helper.keepEngNum(userForm.user_name)"
                maxlength="16"
                show-word-limit
                placeholder="用户名仅限英文或数字"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="密码">
              <el-input v-model="password" show-password :disabled="disabled11"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="姓名" prop="stff_name">
              <select-input
                v-if="flag"
                :selTableData="selTableData"
                :tableHeader="staffTableHeader"
                @getSelTableRow="getSelTableRow"
                :inputVal="stffForm.stff_name"
                :disabled="disabled"
                :isShowCol="false"
                :rowSelect="rowSelect"
                placeholder="请选择员工姓名"
              >
              </select-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="部门" prop="dept_name">
              <el-input v-model="stffForm.dept_name" :disabled="disabled11" placeholder="员工所在部门"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="小组" prop="dept_team_name">
              <el-input v-model="stffForm.dept_team_name" :disabled="disabled11" placeholder="员工所在小组"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="vd_rang vg_partition">
        <el-col :md="4" align="center"> 数据可见范围 <span class="vg_deep_red">(必选)</span>： </el-col>
        <el-col :md="18">
          <el-card shadow="never" style="display: flex; justify-content: center; height: 400px; overflow: auto">
            <el-row>
              <el-col :md="16" class="vg_mb_8">
                <el-col :offset="8">
                  <el-button :disabled="disabled" size="mini" type="primary" @click="setValue(0)">全部全局</el-button>
                  <el-button :disabled="disabled" size="mini" type="primary" @click="setValue(1)">全部部门</el-button>
                  <el-button :disabled="disabled" size="mini" type="primary" @click="setValue(2)">全部小组</el-button>
                  <el-button :disabled="disabled" size="mini" type="primary" @click="setValue(3)">全部个人</el-button>
                </el-col>
              </el-col>
            </el-row>
            <el-tree style="width: 100%" :data="permRangeList" node-key="perm_id" :empty-text="'暂无分配权限'" default-expand-all :expand-on-click-node="false">
              <el-row class="custom-tree-node" slot-scope="{ node, data }">
                <el-col :md="16">
                  <el-col :md="8">{{ data.perm_name }}</el-col>
                  <el-radio-group v-model="data.user_scope">
                    <el-radio :label="0">全局</el-radio>
                    <el-radio :label="1">部门</el-radio>
                    <el-radio :label="2">小组</el-radio>
                    <el-radio :label="3">个人</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col :md="8" v-if="data.user_scope === 1">
                  <el-select collapse-tags size="mini" filterable multiple v-model="data.dept_id_list" placeholder="请选择部门" clearable>
                    <el-option v-for="item in deptList" :key="item.label" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-tree>
          </el-card>
          <!--          <el-radio-group v-model="userForm.user_scope">-->
          <!--            <el-radio :label="item.value" :key="item.value" v-for="item in isRangeData">{{ item.label }}</el-radio>-->
          <!--          </el-radio-group>-->
        </el-col>
      </el-row>
      <el-row class="vd_rang vg_partition">
        <el-col :md="4" align="center"> 特殊数据范围<span class="vg_deep_red">(VIP) </span>： </el-col>
        <el-col>
          <el-form size="mini" class="vd_horiArr" :model="userForm" :disabled="disabled">
            <div class="vd_butDiv">
              <el-button type="primary" plain size="mini" @click="addType()">添加</el-button>
            </div>
            <el-table class="vd_elTable" :data="userForm.user_scpe_list" border max-height="200">
              <el-table-column label="模块名称">
                <template slot-scope="scope">
                  <el-form-item label-width="0" :prop="'user_scpe_list.' + scope.$index + '.perm_id'" :rules="[{ required: true }]">
                    <el-select
                      filterable
                      @change="selectChange(scope)"
                      v-model="userForm.user_scpe_list[scope.$index].perm_id"
                      placeholder="请选择模块名称"
                      clearable
                    >
                      <el-option v-for="item in permForm" :key="item.perm_id" :label="item.perm_name" :value="item.perm_id"> </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="类型">
                <template slot-scope="scope">
                  <el-form-item label-width="0">
                    <el-checkbox @change="viewFlagChange(scope)" v-model="userForm.user_scpe_list[scope.$index].view_flag">全局查看</el-checkbox>
                    <el-checkbox @change="editFlagChange(scope)" v-model="userForm.user_scpe_list[scope.$index].edit_flag">全局编辑</el-checkbox>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" width="150px">
                <template slot-scope="scope">
                  <el-link type="danger" @click="delType(scope)" :disabled="disabled">移除</el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="4" align="center"> 账号角色分配<span class="vg_deep_red">(必选)</span>： </el-col>
        <el-col>
          <div style="text-align: center">
            <el-transfer
              style="text-align: left; display: inline-block"
              v-model="value"
              :titles="['角色总表', '角色赋表']"
              :button-texts="['去掉角色', '给予角色']"
              :data="roleList"
            >
            </el-transfer>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { userAPI } from '@api/modules/user';
import { stffAPI } from '@api/modules/staff';
import { roleAPI } from '@api/modules/role';
import { permAPI } from '@api/modules/perm';
import { staffTableHeader } from '@/views/component/tableHeader/staffTableHeader';
import selectInput from '@/views/component/selectInput';
import inputUser from '@/views/component/inputUser';
import { getDept } from '@api/public';

export default {
  name: 'UserAdd',
  components: {
    selectInput,
    inputUser
  },
  data() {
    //验证账号
    let checkUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('账号不能为空'));
      }
    };
    return {
      disabled: false,
      disabled11: true,
      userForm: {
        user_id: null,
        user_name: '',
        user_scope: 3,
        user_role_id_list: [],
        user_scpe_list: []
      },
      stffForm: {
        stff_name: '',
        stff_id: Number,
        dept_id: Number,
        dept_team_id: Number
      },
      flag: true,
      roleData: [],
      rowSelect: [],
      permForm: [],
      value: [],
      roleList: [],
      password: '123456',
      selTableData: [],
      staffTableHeader: [],
      isRangeData: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '部门'
        },
        {
          value: 2,
          label: '小组'
        },
        {
          value: 3,
          label: '个人'
        }
      ],
      rules: {
        // user_name: [ {required: true, validator: checkUserName, trigger: 'blur'}],
        user_name: [{ required: true, min: 6, max: 16, message: '账号长度为6-16', trigger: 'blur' }],
        dept_name: [{ required: true, trigger: 'blur' }],
        stff_name: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      deptList: []
    };
  },
  computed: {
    /**
     * 计算属性: permRangeList
     * 功能: 根据当前选中的角色ID列表(this.value)，从角色权限数据(this.roleData)中提取所有独特的权限项，
     *       并确保每个权限（依据perm_id）在结果中只出现一次。最后，以数组形式返回这些唯一权限对象。
     * 返回值: 包含所有唯一权限对象的数组。
     */
    permRangeList() {
      const map = new Map();
      this.value.forEach(item =>
        this.roleData
          .find(x => x.role_id === item)
          ?.permissions.forEach(subItem => {
            if (!map.has(subItem.perm_id)) map.set(subItem.perm_id, subItem);
          })
      );
      return Array.from(map.values());
    }
  },
  async created() {
    this.getSelectTable();
    this.getAllRoles();
    this.staffTableHeader = staffTableHeader;
    this.roleList = [];
    this.getPerms();
    this.deptList = await getDept();
  },
  methods: {
    //获取权限信息
    getPerms() {
      get(permAPI.getAllPerms)
        .then(res => {
          if (res.data.code === 0) {
            let permForm = res.data.data;
            for (let i = 0; i < permForm.length; i++) {
              if (permForm[i].perm_child_list !== null) {
                for (let j = 0; j < permForm[i].perm_child_list.length; j++) {
                  if (permForm[i].perm_child_list[j].perm_id === 103 || permForm[i].perm_child_list[j].perm_id === 109) {
                    let temp = {};
                    temp.perm_id = permForm[i].perm_child_list[j].perm_id;
                    temp.perm_name = permForm[i].perm_child_list[j].perm_name;
                    this.permForm.push(temp);
                  }
                }
              }
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取传递selTableData
    getSelectTable() {
      get(stffAPI.getAllStffsV1).then(res => {
        if (res.data.code === 0) {
          this.selTableData = res.data.data;
        }
      });
    },
    // 获取双击selTableData的row
    getSelTableRow(val) {
      this.stffForm = val;
    },
    // 全局查看和全局编辑影响
    viewFlagChange(scope) {
      if (scope.row.perm_id !== null) {
        if (!scope.row.view_flag) {
          scope.row.edit_flag = false;
        }
      } else {
        scope.row.edit_flag = false;
        scope.row.view_flag = false;
      }
    },
    editFlagChange(scope) {
      if (scope.row.perm_id !== null) {
        if (scope.row.edit_flag) {
          scope.row.view_flag = true;
        }
      } else {
        scope.row.edit_flag = false;
        scope.row.view_flag = false;
      }
    },
    // 特殊数据范围添加
    addType() {
      let item = {
        perm_id: null,
        view_flag: null,
        edit_flag: null
      };
      this.userForm.user_scpe_list.push(item);
    },
    // 特殊数据范围添加删除
    delType(scope) {
      // console.log('scope',scope,this.userForm.user_scpe_list)
      this.userForm.user_scpe_list.splice(scope.$index, 1);
    },
    // select变化提示
    selectChange(val) {
      if (val.row.perm_id !== null) {
        for (let i = 0; i < this.userForm.user_scpe_list.length; i++) {
          if (i !== val.$index) {
            if (this.userForm.user_scpe_list[i].perm_id === val.row.perm_id) {
              val.row.perm_id = null;
              this.$message({
                type: 'warning',
                message: '账套选择重复'
              });
            }
          }
        }
      }
    },
    //获取全部角色信息
    getAllRoles() {
      get(roleAPI.getAllRolesV1).then(res => {
        if (res.data.code === 0) {
          if (this.roleList.length === 0) {
            this.roleData = res.data.data;
            for (let i = 0; i < this.roleData.length; i++) {
              this.roleList.push({
                key: this.roleData[i].role_id,
                label: this.roleData[i].role_name
              });
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 提交表单
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.stffForm.stff_name === '') {
            let mg = '请选择员工姓名！';
            let tp = 'warning';
            this.$message({ message: mg, type: tp });
          } else if (this.value.length === 0) {
            let mg = '请选择至少一个角色';
            let tp = 'warning';
            this.$message({ message: mg, type: tp });
          } else {
            this.saveInfo();
          }
        })
        .catch(() => {
          this.$message.info('已取消保存');
        });
    },
    //保存
    saveInfo() {
      const userAddBody = {};
      userAddBody.user_name = this.userForm.user_name;
      userAddBody.user_pass = this.password;
      userAddBody.stff_name = this.stffForm.stff_name;
      userAddBody.stff_id = this.stffForm.stff_id;
      userAddBody.dept_id = this.stffForm.dept_id;
      userAddBody.dept_team_id = this.stffForm.dept_team_id;
      userAddBody.user_scope = this.userForm.user_scope;
      userAddBody.user_role_id_list = this.value;
      userAddBody.userScopePerms = this.permRangeList;
      let userForm = {};
      userForm = Object.assign(userForm, this.userForm);
      userAddBody.user_scpe_list = userForm.user_scpe_list;
      for (let i = 0; i < userAddBody.user_scpe_list.length; i++) {
        if (userAddBody.user_scpe_list[i].edit_flag === true) {
          userAddBody.user_scpe_list[i].edit_flag = 1;
        } else if (userAddBody.user_scpe_list[i].edit_flag === false || userAddBody.user_scpe_list[i].edit_flag === null) {
          userAddBody.user_scpe_list[i].edit_flag = 0;
        }
        if (userAddBody.user_scpe_list[i].view_flag === true) {
          userAddBody.user_scpe_list[i].view_flag = 1;
        } else if (userAddBody.user_scpe_list[i].view_flag === false || userAddBody.user_scpe_list[i].view_flag === null) {
          userAddBody.user_scpe_list[i].view_flag = 0;
        }
      }
      post(userAPI.addUser, userAddBody).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '添加成功'
          });
          this.getSelectTable();
          this.resetForm('userForm');
          this.jump('/user_edit', { perm_id: this.$route.query.perm_id, form_id: res.data.data.form_id });
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });
        }
      });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('userForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.stffForm.stff_name = '';
      this.stffForm.dept_name = '';
      this.stffForm.dept_team_name = '';
      this.userForm.user_scope = 3;
      this.value = [];
    },
    setValue(val) {
      const rolePermissionsMap = this.roleData.reduce((acc, role) => {
        acc[role.role_id] = role.permissions;
        return acc;
      }, {});

      this.value.forEach(roleId => {
        rolePermissionsMap[roleId]?.forEach(permission => {
          permission.user_scope = val;
        });
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-card__body {
  width: 100%;
}
.vd_horiArr {
  width: 50%;
  text-align: center;
  margin-left: 20%;
  display: flex;
  justify-content: space-around;
}
.vd_butDiv {
  width: 20%;
}
.vd_elTable {
  width: 500px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  padding-right: 8px;
}
</style>
